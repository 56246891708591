<div class="main-content">
    <div class="permit-requester-details" *ngIf="currentPermit">
        <div id="closePermitTitle" fxLayout="row">
            <div fxFlex="20">
                <div class="logo">
                    <img src="/assets/CityFM Logo.png">
                </div>
            </div>
            <div fxFlex="80" fxLayoutAlign="end center">
                <div data-testid="open_permit_title">
                    <span class="font-size-h1 color-city-blue">{{'open_permit_permits_lbl' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="divider"></div>
        <mat-divider></mat-divider>
        <div class="divider"></div>
        <div id="closePermitHeader" fxLayout="row">
            <div fxFlex="50">
                <div><mat-label>{{'close_permit_lbl' | translate}}</mat-label></div>
            </div>
            <div fxFlex="50" fxLayoutAlign="end center">
                <div data-testid="permit_number">
                    <span class="header-lbl-font">{{'permit_number_lbl' | translate}}
                        {{currentPermit.permitNumber}}</span>
                </div>
            </div>
        </div>
        <div id="closePermitHeader_detail1" fxLayout="row">
            <div fxFlex="50">
                <div data-testid="permit_name">
                    <span class="header-lbl-font">{{'permit_name_lbl' | translate}}
                        {{currentPermit.userFullName}}</span>
                </div>
            </div>
            <div fxFlex="50" class="company-name">
                <div data-testid="permit_company">
                    <span class="header-lbl-font">{{'permit_company_lbl' | translate}}
                        {{currentPermit.userCompany}}</span>
                </div>
            </div>
        </div>
        <div id="closePermitHeader_detail2" fxLayout="row">
            <div fxFlex="50">
                <div data-testid="permit_date">
                    <span class="header-lbl-font">{{'permit_date_lbl' | translate}} {{currentPermit.createdAt |
                        date:'dd-MM-yyyy'}}</span>
                </div>
            </div>
            <div fxFlex="50">
                <div data-testid="permit_time">
                    <span class="header-lbl-font">{{'permit_time_lbl' | translate}} {{currentPermit.createdAt |
                        date:'hh:mm a'}}</span>
                </div>
            </div>
        </div>
        <div class="divider"></div>
        <mat-divider></mat-divider>
    </div>

    <div class="permit-questions" *ngIf="questionArrays">
        <div *ngFor="let question of questionArrays">
            <app-permit-question-render [question]="question"
                [hasContentError]="question.HasContentError"></app-permit-question-render>
        </div>
    </div>

    <div class="button-box" fxLayout="row wrap" fxLayoutAlign="space-between center">
        <div class="right" fxFlex.gt-sm="30">
            <button mat-raised-button (click)="onCancel()">{{ ('close_permit_button_cancel' | translate)
                }}</button>
        </div>
        <div class="right" fxFlex.gt-sm="30">
            <button mat-raised-button type="submit" (click)="onSubmit()">{{ ('close_permit_button_complete' |
                translate) }}</button>
        </div>
    </div>

</div>