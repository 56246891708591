<ng-container>
    <div [ngSwitch]="question.QuestionType.trim().toLowerCase()" id="switch_question">
        <div *ngSwitchCase="'radiobutton'"><app-permit-radiobutton [question]="question"
                [hasContentError]="hasContentError"></app-permit-radiobutton></div>
        <div *ngSwitchCase="'multiplechoice'"><app-permit-multiple-choice [question]="question" [hasContentError]="hasContentError"></app-permit-multiple-choice></div>
        <div *ngSwitchCase="'signature'"><app-permit-signature [question]="question" [hasContentError]="hasContentError"></app-permit-signature></div>
        <div *ngSwitchCase="'label'"><app-permit-label [question]="question"></app-permit-label></div>
        <div *ngSwitchCase="'text'"><app-permit-text-box [question]="question" [hasContentError]="hasContentError"></app-permit-text-box></div>
        <div *ngSwitchCase="'separator'"><app-permit-separator [question]="question"></app-permit-separator></div>
        <div *ngSwitchCase="'group'"><app-permit-group [question]="question" [hasContentError]="hasContentError"></app-permit-group></div>
        <div *ngSwitchCase="'currentdate'"><app-permit-currentdate [question]="question"></app-permit-currentdate></div>
        <div *ngSwitchCase="'currenttime'"><app-permit-currenttime [question]="question"></app-permit-currenttime></div>
        <div *ngSwitchDefault>{{question.Caption}} - {{question.QuestionType}} - Not implemented</div>
    </div>
    <div class="divider"></div>
</ng-container>