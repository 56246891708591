<div class="main-content" #topPosition>
    <div fxLayout="column">
        <div id="openPermitTitle" fxLayout="row">
            <div fxFlex = "20">
                <div class="logo" >
                    <img src="/assets/CityFM Logo.png">
                </div>
            </div>
            <div fxFlex = "80" fxLayoutAlign="end center" >
                <div data-testid="open_permit_title">
                    <span class="font-size-h1 color-city-blue">{{'open_permit_permits_lbl' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="divider"></div>
        <mat-divider></mat-divider>
        <div class="divider"></div>
        <div id="openPermitHeader" fxLayout="row">
            <div fxFlex = "30">
                <div><mat-label>{{'open_permit_lbl' | translate}}</mat-label></div>
            </div>
            <div fxFlex = "70" fxLayoutAlign="end center">
                <div data-testid="permit_number">
                    <span class="header-lbl-font">{{'permit_number_lbl' | translate}} {{currentPermit?.permitNumber}}</span>
                </div>
            </div>           
        </div>
        <div id="openPermitHeader_detail1" fxLayout="row">
            <div fxFlex = "50">
                <div data-testid="permit_name">
                    <span class="header-lbl-font">{{'permit_name_lbl' | translate}} {{currentPermit?.userFullName}}</span>
                </div>
            </div> 
            <div fxFlex = "50" class="company-name">
                <div data-testid="permit_company">
                    <span class="header-lbl-font">{{'permit_company_lbl' | translate}} {{currentPermit?.userCompany}}</span>
                </div>
            </div>           
        </div>
        <div id="openPermitHeader_detail2" fxLayout="row">
            <div fxFlex = "50">
                <div data-testid="permit_date">
                    <span class="header-lbl-font">{{'permit_date_lbl' | translate}} {{currentPermit?.createdAt | date:'dd-MM-yyyy'}}</span>
                </div>
            </div> 
            <div fxFlex = "50">
                <div data-testid="permit_time">
                    <span class="header-lbl-font">{{'permit_time_lbl' | translate}} {{currentPermit?.createdAt | date:'hh:mm a'}}</span>
                </div>
            </div>           
        </div>
        <div class="divider"></div>
        <mat-divider></mat-divider>
        <div class="divider"></div>
        <div>
            <form [formGroup]="permitInfoFormGroup" class="prmit-form">
                <mat-form-field appearance="fill" class="input">
                  <mat-label class="color-city-neutral">{{ ('permit_work_order_number_lbl' | translate) }}</mat-label>
                  <input matInput placeholder="{{'permit_work_order_number_field' | translate }}" 
                  formControlName="workOrderNumber" required id="input_permit_workorder_number" >
                  <mat-error *ngIf="hasError(permitInfoFormGroup, 'workOrderNumber')">{{getError(permitInfoFormGroup, 'workOrderNumber')}}</mat-error>
                </mat-form-field>
                <mat-divider></mat-divider>
                <mat-form-field appearance="fill" class="input">
                  <mat-label class="color-city-neutral">{{ ('permit_additional_work_details_lbl' | translate) }}</mat-label>
                  <input matInput placeholder="{{'permit_additional_work_details_field' | translate }}" 
                  formControlName="additionalWorkDetails" required id="input_permit_additional_work_details" >
                  <mat-error *ngIf="hasError(permitInfoFormGroup, 'additionalWorkDetails')">{{getError(permitInfoFormGroup, 'additionalWorkDetails')}}</mat-error>

                </mat-form-field>
              </form>            
        </div>
        <div class="divider"></div>
        <mat-divider></mat-divider>
        <div class="divider"></div>
        <div id="openPermitContent">
            <form novalidate name="permit.qnaForm">
                <div class="form-group" *ngFor="let question of openPermitQnAs">  
                    <ng-form name="templateFieldForm">
                        <app-permit-question-render [question]="question" [hasContentError]="question.HasContentError"></app-permit-question-render>
                    </ng-form>
                </div>
            </form>
        </div>        
        <div class="divider"></div>
        <div fxLayout="row" fxLayoutGap="0.5em">
            <button mat-raised-button (click)="cancelOnClick()" id="btn_permitcancel" data-testid="permit_btn_cancel">
                {{ ('open_permit_cancel_btn' | translate) }}
            </button>
            <button mat-raised-button (click)="saveAsDraftOnClick()" id="btn_permitsave" data-testid="permit_btn_save">
                {{ ('open_permit_saveasdraft_btn' | translate) }}
            </button>
            <button mat-raised-button (click)="submitOnClick()" id="btn_permitsubmit" data-testid="permit_btn_submit">
                {{ ('open_permit_submit_btn' | translate) }}
            </button>
        </div>
    </div>
</div>