import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth/auth.service';
import { LoaderService } from '@app/services/loader/loader.service';
import { LocalizationService } from '../internationalization/localization.service';
import { UtilsService } from '@app/services/util/util-service';
import { NavigationService } from '@app/services/navigation/navigation.service';
import { SignInSignOut, User } from '@app/models/user.model';
import { UserService } from '@app/services/user/user.service';
import { PermitService } from '@app/services/permit/permit-service';
import { Permit } from '@app/models/permit.model';
import { PermitQnA } from '@app/models/permitqna.model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-open-permit',
  templateUrl: './open-permit.component.html',
  styleUrls: ['./open-permit.component.scss']
})
export class OpenPermitComponent implements OnInit{
  permitInfoFormGroup! : UntypedFormGroup;
  currentUser: User | undefined;
  signInSignOut: SignInSignOut | undefined;
  currentPermit: Permit | undefined;
  openPermitQnAs: any = [];//PermitQnA[] = [];
  questions: any;
  hasContentError: boolean = false;
  errorMessage: string = '';

  multipleChoiceType: string = "multiplechoice";
  signatureType: string = "signature";
  radioButtonType: string = "radiobutton";
  inputTextType: string = "text";

  private hasErrorTrueRegex = /,"HasContentError":true/g; 
  private hasErrorFalseRegex = /,"HasContentError":false/g;
  
  constructor(private _authService: AuthService,
    private _userService: UserService,
    private _permitService: PermitService,
    public _router: Router,
    private _formBuilder: UntypedFormBuilder,
    private _localizationService: LocalizationService,
    private _utilsService: UtilsService,
    private _navigationService:NavigationService,
    private changeDetectorRef: ChangeDetectorRef) {
      this._navigationService.onChange(false, false);
  }

  async ngOnInit(): Promise<void> {
    this._authService.getUser().then(user => {
      this.currentUser = user;
    });
    this.permitInfoFormGroup = this.initFormGroup();
    this.checkSignInSignOut();

  }

  private initFormGroup(): UntypedFormGroup {
    const openPermitForm = this._formBuilder.group({
      workOrderNumber: new UntypedFormControl(this.currentPermit?.workOrderNumber, [Validators.required] ),
      additionalWorkDetails: new UntypedFormControl(this.currentPermit?.additionalWorkDetails, [Validators.required])
    });

    return openPermitForm;
  }

  public hasError(form: UntypedFormGroup, controlName: string): boolean {
    const validationOutput = this.getError(form, controlName);
    return validationOutput !== '';
  }

  public getError(form: UntypedFormGroup, controlName: string): string {
    switch (controlName) {
      case 'workOrderNumber':
        if (this.formHasError(form, controlName, 'required')) {
          return this._localizationService.translate('permit_work_order_number_required');
        } 
        break;
      case 'additionalWorkDetails':  
        if (this.formHasError(form, controlName, 'required')) {
          return this._localizationService.translate('permit_additional_work_details_required');
        } 
        break;
    }
    return '';
  }

  private formHasError(form: UntypedFormGroup, controlName: string, errorName: string): boolean {
    return form.controls[controlName].hasError(errorName);
  }

  disableSubmitButton(){
    return !this.permitInfoFormGroup.valid;
  }

  checkSignInSignOut() {
    this._userService.getSignIn().then(signInInfo => {
      if (signInInfo !== undefined && signInInfo.signInSignOutId !== "") {
        this._permitService.getPermitWithFormBySignInSignOutId(signInInfo.signInSignOutId).subscribe(permit => {
          if (permit) {
            this.currentPermit = permit;
            this.PopulatePermitQnA();
          }
          else {
            let postPermit: Permit = {
              id: undefined,
              userFullName: undefined,
              userCompany: signInInfo.complianceCompany,
              permitNumber: undefined,
              signInSignOutId: signInInfo.signInSignOutId,
              siteId: signInInfo.siteId,
              status: 0,
              createdAt: undefined,
              openPermit: undefined,
              welfareChecks: [],
              closePermit: undefined
            }

            this._permitService.createPermit(postPermit).subscribe({
              next: async (success) => {
                  this.currentPermit = success;
                  this.PopulatePermitQnA();
              },
              error: (reject) => {
                alert(this._localizationService.translate("open_permit_error_create_permit"));
                this._router.navigate(['siteinductioncompleted']);
              }
            })
          }

        });
      }
      else {
        alert(this._localizationService.translate('open_permit_no_signin_found'));
        this._router.navigate(['signin']);
      }
    });
  }

  PopulatePermitQnA() {
    if (this.currentPermit) {
      if (this.currentPermit.openPermit) {
        this.openPermitQnAs = JSON.parse(this.currentPermit.openPermit.qnA);
        if (this.openPermitQnAs.length <= 0) {
          alert(this._localizationService.translate("open_permit_no_questions_found"));
          this._router.navigate(['signin']);
        }
      }
    }
   
  }

  cancelOnClick() {
    this._router.navigate(['siteinductioncompleted']);
  }

  /**
   * Helper method for removing unnecessary properties from the permit questions JSON.
   * @param permitQnAs 
   *  JSON object containing permit questions
   * @returns 
   *  A stringified JSON object with all unnecessary properties removed.
   */
  private sanitizePermitQnA(permitQnAs: any): string {
    let permitQnASanitized = JSON.stringify(permitQnAs)
      .replace(this.hasErrorTrueRegex, '')
      .replace(this.hasErrorFalseRegex, '');

    return permitQnASanitized;
  }

  saveAsDraftOnClick(){
    if (this.currentPermit && this.currentPermit.openPermit != null) {
      let permitQnASanitized = this.sanitizePermitQnA(this.openPermitQnAs);
      this.currentPermit.openPermit.qnA = btoa(permitQnASanitized);
      
      this._permitService.draftPermit(this.currentPermit).subscribe({
        next: async (result) => {
          if(result != null)
          {
            alert(this._localizationService.translate("open_permit_save_draft_success"));
          }
          else{
            alert(this._localizationService.translate("open_permit_save_draft_error"));
          }
        },
        error: (reject) => {
          alert(this._localizationService.translate("open_permit_save_draft_error"));
        }
      });
    }
    else{
      alert(this._localizationService.translate("open_permit_save_draft_error_current_permit_missing"));
    }
  }

  submitOnClick(){
    if (this.currentPermit && this.currentPermit.openPermit != null) {
      this.hasContentError = this.performErrorValidation(this.openPermitQnAs);

      if(!this.hasContentError){
        let permitQnASanitized = this.sanitizePermitQnA(this.openPermitQnAs);
        this.currentPermit.openPermit.qnA = btoa(permitQnASanitized);

        this._permitService.submitPermit(this.currentPermit).subscribe({
          next: async (result) => {
            if(result != null)
            {
              alert(this._localizationService.translate("open_permit_submit_permit_success"));
              this._router.navigate(['siteinductioncompleted']);
            }
            else{
              alert(this._localizationService.translate("open_permit_submit_permit_error"));
            }
          },
          error: (reject) => {
            alert(this._localizationService.translate("open_permit_submit_permit_error"));
          }
        });
      }
      else{
        alert(this._localizationService.translate('open_permit_error_required_fields_empty'));
      }
    }
    else{
      alert(this._localizationService.translate("open_permit_submit_permit_error_current_permit_missing"));
    }
  }

  /**
   * Helper method for validating errors in the permit questions.
   * @param QnAs 
   *  JSON object containing permit questions
   * @returns 
   *  True if errors found, false otherwise.
   */
  performErrorValidation(QnAs: any): boolean{
    let errorsFound = false;

    if(QnAs){
      QnAs.forEach((question: any) => {
        // Set value to false to reset change detection if value was previously true
        question.HasContentError = false;
        this.changeDetectorRef.detectChanges();

        if(question.IsRequired){
          if(!question.Answer || question.Answer.trim() === ""){
            errorsFound = true;
            question.HasContentError = true;

            this.errorMessage = this._localizationService.translate('open_permit_error_required_fields_empty');
          }
          else{
            question.HasContentError = false;
          }

          this.changeDetectorRef.detectChanges();
        }

        // Validate nested Rules
        if(question.Rules !== null && question.Rules !== undefined && question.Rules.length > 0){
          question.Rules.forEach((rule: any) => {
            if(String(question.Answer).includes(String(rule.Value)) ){
              const validationResults = this.performErrorValidation(rule.RuleDetails);
              // Only allow errorsFound to switch value to true, and never from true to false
              errorsFound = validationResults ? validationResults : errorsFound;
            }
          });
        }

        // Validate nested Groups
        if(question.GroupingDetails !== null && question.GroupingDetails !== undefined && question.GroupingDetails.length > 0){
          const validationResults = this.performErrorValidation(question.GroupingDetails);
          // Only allow errorsFound to switch value to true, and never from true to false
          errorsFound = validationResults ? validationResults : errorsFound;
        }
      });
    }

    return errorsFound;
  }
}
