<mat-label class="font-size-headline color-city-blue" [ngStyle]="captionStyles()"
  id="{{question.Id}}">{{question.Caption}}</mat-label>
<div *ngFor="let option of options; let indexOfelement=index;">
  <div fxLayout="row">
    <div fxLayout="column" style="padding-right: 0.5em;">
      <mat-checkbox value={{option}} (change)="setAnswer($event)" checked={{indexSelected[indexOfelement]}}
        id="questionOption{{question.Id}}{{indexOfelement}}">
        <div fxLayout="row" [ngStyle]="questionDetailsStyles()" style="align-items: center;">
          <span class="checkbox-textwrap color-dark-blue font-size-small-text">
            {{option}}
            <mat-label *ngIf="question.IsRequired" class="color-city-red"> *</mat-label>
          </span>
        </div>
      </mat-checkbox>
    </div>
  </div>

  <div fxLayout="row" *ngIf="expendChilds(option)">
    <div fxFlex="100" fxLayout="column">
      <div class="rules-render" *ngFor="let nextQuestion of getRuleDetails(option)">
        <div *ngIf="nextQuestion">
          <app-permit-question-render [question]="nextQuestion"
            [hasContentError]="nextQuestion.HasContentError"></app-permit-question-render>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-error *ngIf="question.IsRequired && !question.Answer && (answerTouched || question.HasContentError)">
  {{ isRequiredMessageDisplay }}
</mat-error>